const $ = window.jQuery;
$(document).on('ready', () => {
	const $lang = $('.languages--multiple');
	$lang.hover((e) => {
		const $this = $(e.currentTarget);
		$this.addClass('active');
	}, (e) => {
		const $this = $(e.currentTarget);
		$this.removeClass('active');
	});
});
