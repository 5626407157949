//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import helpers from '../../helpers';

export default {
  name: "Totals",
  components: {
  },
  computed: {
    products() {
        return this.$store.state.cart.products;
    },
    vatTotal() {
        const cartItems = this.$store.state.cart.products;
        let total = 0;
        for (let i = 0; i < cartItems.length; i++ ) {
            total += helpers.getCartItemPrice(cartItems[i], false);
        }
        total = helpers.getVatFromPrice(total);
        return helpers.getPrice(total, false);
    },
    grandTotalExcludedVat() {
        const cartItems = this.$store.state.cart.products;
        let total = 0;
        for (let i = 0; i < cartItems.length; i++ ) {
            total += helpers.getCartItemPrice(cartItems[i], false);
        }
        return helpers.getPrice(total);
    },
    grandTotal() {
        const cartItems = this.$store.state.cart.products;
        let total = 0;
        for (let i = 0; i < cartItems.length; i++ ) {
            total += helpers.getCartItemPrice(cartItems[i], false);
        }
        return helpers.getPrice(total, false);
    }
  },
  data() {
    return {
      assetsUrl: window.urls.assets,
      strings: window.app_config.strings,
      pages: window.app_config.pages,
      texts: window.app_config.texts.cart,
      couponCode: null,
      userEmail: null
    }
  },
  methods: {
    applyCoupon() {

    },
    goToCheckout() {

    }
  }
};
