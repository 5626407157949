(($) => {

	$('.thankyou__main__bottom .btn').click((e) => {
		const $this = $(e.currentTarget);
		$('.modal').fadeIn();
	});
	
    $('.modal .modal__close').click((e) => {
		const $this = $(e.currentTarget);
		$this.parents('.modal').fadeOut();
	});

})(window.jQuery);
