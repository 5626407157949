
<template>
    <div class="cart-row" v-if="product">
        <div class="cart-col cart-col--left">
            <div class="cart-col__block">
                <div class="product-cart-item">
                    <div class="product-cart-item__image">
                        <a :href="product.url" title="">
                            <img :src="getImage()" alt="" class="product-image" />
                        </a>
                    </div>
                    <div class="product-cart-item__main">
                        <div class="product-cart-item">
                            <strong v-html="product.title"></strong>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
        <div class="cart-col cart-col--right">
            <div class="cart-col__block cart-col__block--33">
                <div class="quantity">
                    <button @click.prevent="qtyDown" class="quantity__symbol minus">
                        <img :src="`${assetsUrl}/svg/minus.svg`" width="24" height="24" alt=""/>
                    </button>
                    <div class="quantity__number">{{ cartItem.quantity }}</div>
                    <button @click.prevent="qtyUp" class="quantity__symbol plus">
                        <img :src="`${assetsUrl}/svg/plus.svg`" width="24" height="24" alt=""/>
                    </button>
                </div>
            </div>
            <div class="cart-col__block cart-col__block--33">
                <strong v-html="lineTotal"></strong>
            </div>
            <div class="cart-col__block cart-col__block--33">
                <a href="#" @click.prevent="removeItem" title="">
                    <img :src="`${assetsUrl}/svg/remove.svg`" width="24" height="24" alt=""/>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import helpers from '../../helpers';

export default {
    name: "AccessoryRow",
    props: ['rowItem'],
    data() {
        return {
            assetsUrl: window.urls.assets,
            strings: window.app_config.strings,
            product: null,
        }
    },
    computed: {
        cartItem() {
            return this.$store.state.cart.products[this.$props.rowItem.cartIndex];
        },
        lineTotal() {
            const total = this.getItemPrice(false);
            return helpers.getPrice(total * this.cartItem.quantity);
        }
    },
    beforeMount() {
        this.product = helpers.getProductById(this.$props.rowItem.item.id);
    },
    methods: {
        qtyDown() {
            this.$store.commit('cart/update_qty', { 
                index: this.$props.rowItem.cartIndex,
                type: 'minus'
            });
        },
        qtyUp() {
            this.$store.commit('cart/update_qty', { 
                index: this.$props.rowItem.cartIndex,
                type: 'plus'
            });
        },
        removeItem() {
            this.$store.commit('cart/remove_product', { index: this.rowIndex });
        },
        getItemPrice(formatted = true) {
            let price = this.product.price;
            if (this.product.on_sale) {
                price = this.product.sale_price;
            }
            if (formatted) {
                return helpers.getPrice(price);
            }
            return price;
        },
        getImage() {
            const color = this.product.colors.filter(item => item.name === this.$props.rowItem.item.color);
            if (color.length) {
                return color[0].image;
            }
            return this.product.image;
        }
    }
};
</script>
