const $ = window.jQuery;
$(document).on('ready', () => {

  $('.products .product-item').hover((e) => {
    var current_offset = $(e.currentTarget).offset();
    
    $('.products .product-item').each((index, el) => {
      var el_offset = $(el).offset();

      if( el_offset.top == current_offset.top ){
        $(el).find('.product-item__details').stop().slideToggle(200);
      } 
    })
  });

  $('.products__filters ul.filters li').click((e) => {
    e.preventDefault();
    var current_cat_id = $(e.currentTarget).data('catid');
    $(e.currentTarget).addClass('active');
    $(e.currentTarget).siblings().removeClass('active');

    if( current_cat_id == 'all' ){
      $('.products .product-item').fadeIn(400);
      return;  
    }
    
    $('.products .product-item').each((index, el) => {
      var el_cat_id = $(el).data('catid');

      if( el_cat_id == current_cat_id ){
        $(el).fadeIn(400);
      }else{
        $(el).fadeOut(0);
      }
    })
  });

  $('.toggle-filters').click((e) => {
    $('.products__filters').slideToggle();
  });

});