//
//
//
//
//
//
//
//
//
//
//
//
//


import PosRow from './PosRow';
import ApolloRow from './ApolloRow';

import helpers from '../../helpers';

export default {
  name: "PosTable",
  components: {
    PosRow,
    ApolloRow
  },
  computed: {
    products() {
      const posProducts = [];
      const cartProducts = this.$store.state.cart.products;
      for (let i = 0; i < cartProducts.length; i++) {
        const product = helpers.getProductById(cartProducts[i].id);
        if (product.type === 'pos') {
          posProducts.push({
            item: cartProducts[i],
            cartIndex: i
          });
        }
      }
      return posProducts;
    }
  }
};
