const $ = window.jQuery;

$(document).on('ready', () => {
	const cookieBotLink = $('a[href="#show-cookie-bot"]');

	cookieBotLink.on('click', (e) => {
		e.preventDefault();
		if (window.Cookiebot) {
			window.Cookiebot.show()
		}
	});
});

const $footer = $('.site-footer');

function setFooterMargin() {
	const $popup = $('#CybotCookiebotDialog');
	const popupHeight = $popup.outerHeight();
	$footer.css('margin-bottom', popupHeight);
}

function resetFooterMargin() {
	$footer.css('margin-bottom', '');
}

window.addEventListener('CookiebotOnAccept', () => {
	resetFooterMargin();
}, false);

window.addEventListener('CookiebotOnDecline', () => {
	resetFooterMargin();
}, false);

window.addEventListener('CookiebotOnDialogDisplay', () => {
	setFooterMargin();
}, false);