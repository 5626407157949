import Vue from 'vue';

const vat = 0.24; // 24%
const currency = '€';

export default {
    /*
     * @msg      string  any
     * @type     string  success, info, warning, error, default
     * @duration number  notice delay
     */
    notice(msg = '', type = 'success', duration = 3000) {
        Vue.$toast.open({
            message: msg,
            type,
            position: 'bottom',
            duration
        });
    },
    getProductById(id) {
        const products = window.app_config.products;
        let product = null;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id == id) {
                product = products[i];
                break;
            }
        }
        return product;
    },
    getCartItemPrice(cartItem, excludeVat = true, formatted = false) {
        const product = this.getProductById(cartItem.id);
        let price = product.price;
        if (product.on_sale) {
            price = product.sale_price;
        }
        if (product.type === 'pos') {
            const support_price = product.support_plans[cartItem.supportPlanIndex].price;
            price = price + support_price;
        }
        price = price * cartItem.quantity;
        if (formatted) {
            return this.getPrice(price, excludeVat);
        }
        return price;
    },
    getPrice(price, excludeVat = true, appendCurrency = true) {
        if (typeof price === 'string') {
            price = parseFloat(price);
        }
        let finalPrice = price;
        if (excludeVat) {
            const vatTotal = this.getVatFromPrice(price);
            finalPrice = price - vatTotal;
        }
        finalPrice = finalPrice.toFixed(2).toString().replace('.', ',');
        if (appendCurrency) {
            finalPrice = `${currency}${finalPrice}`;
        }
        return finalPrice;
    },
    getVatFromPrice(price) {
        return (price * vat) / (1 + vat);
    },
    /**
     * sends a request to the specified url from a form. this will change the window location.
     * @param {string} action the path to send the post request to
     * @param {object} params the parameters to add to the url
     * @param {string} [method=post] the method to use on the form
     * @param {string} [target=_top] the target to use on the form
     */
    postForm(action, params, method='post', target='_top') {

        // The rest of this code assumes you are not using a library.
        // It can be made less wordy if you use one.
        const form = document.createElement('form');
        form.method = method;
        form.action = action;
        form.target = target;
    
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];
        
                form.appendChild(hiddenField);
            }
        }
    
        document.body.appendChild(form);
        form.submit();
    },
    isPhone(value) {
        return /^[0-9]{10}$/im.test(value);
    },
    isMobilePhone(value) {
        return value.startsWith('69') && value.length === 10 &&
             /^\d+$/.test(value); // is numeric
    },
    /*
     * @return string  Generates random unique id
     */
    uid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}