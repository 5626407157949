
<template>
  <div class="cart-icon">
    <a :href="cartPage" title="">
      {{ strings.my_cart }}
      <img :src="`${assets}/svg/cart.svg`" width="35" height="35" alt=""/>
      <span v-if="cartCount > 0" class="cart-icon__count">{{ cartCount }}</span>
    </a>
    <div v-if="showMiniCart" class="minicart">
      <h3 class="minicart__title" v-html="strings.my_cart"></h3>
      <div v-if="cartCount > 0" class="minicart__counter">
        <span v-html="cartCount"></span>{{ getCounterLabel() }}
      </div>
      <div v-if="cartCount > 0" class="minicart__products">
        <cart-item v-for="(item, index) in cartProducts" :key="index" :product-data="item" :row-index="index"/>
      </div>
      <div v-else>
        <div v-html="strings.no_products_in_cart" class="py-5 h6 text-center"></div>
      </div>
      <div v-if="cartCount > 0" class="minicart__button">
        <a :href="cartPage" class="btn btn--orange btn--wide" title="add-to-cart">
            <span v-html="strings.continue_to_cart"></span><span class="icon icon-arrow-right"></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import CartItem from './components/CartItem';

export default {
  name: "App",
  components: {
    CartItem
  },
  data() {
    return {
      assets: window.urls.assets,
      strings: window.app_config.strings,
      cartPage: window.app_config.pages.cart,
      showMiniCart: false
    }
  },
  computed: {
    cartCount() {
      const products = this.$store.state.cart.products;
      let count = 0;
      for (let i = 0; i < products.length; i++ ) {
        count += products[i].quantity;
      }
      return count;
    },
    cartProducts() {
      return this.$store.state.cart.products;
    }
  },
  beforeMount() {
    if (window.location.href !== window.app_config.pages.cart) {
      this.showMiniCart = true;
    }
  },
  methods: {
    getCounterLabel() {
      if (this.cartCount > 1) {
        return this.strings.products_lowercase;
      }
      return this.strings.product_lowercase;
    }
  }
};
</script>
