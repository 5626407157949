//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import helpers from '../../helpers';

export default {
  name: "CartItem",
  props: ['productData', 'rowIndex'],
  data() {
    return {
      assetsUrls: window.urls.assets,
      strings: window.app_config.strings,
      product: null
    }
  },
  beforeMount() {
    this.product = helpers.getProductById(this.$props.productData.id);
  },
  methods: {
    getTotalPrice() {
      if (!this.product) {
        return;
      }
      let price = this.product.price;
      if (this.product.on_sale) {
        price = this.product.sale_price;
      }
      if (this.product.support_plans && this.product.support_plans.length) {
        if (this.productData.supportPlanIndex >= 0) {
          price = price + this.product.support_plans[this.productData.supportPlanIndex].price;
        }
      }
      return helpers.getPrice(price * this.productData.quantity);
    },
    getPriceWithoutDiscount() {
      if (!this.product) {
        return;
      }
      let price = this.product.price;
      if (this.product.support_plans && this.product.support_plans.length) {
        if (this.productData.supportPlanIndex >= 0) {
          price = price + this.product.support_plans[this.productData.supportPlanIndex].price;
        }
      }
      return helpers.getPrice(price * this.productData.quantity);
    },
    removeItem() {
      this.$store.commit('cart/remove_product', { index: this.rowIndex });
    },
    getImage() {
      if (this.product.type === 'accessory') {
        const color = this.product.colors.filter(item => item.name === this.$props.productData.color);
        if (color.length) {
          return color[0].image;
        }
      }
      return this.product.image;
    }
  }
};
