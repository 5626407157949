(($) => {
	$(window).scroll(() => {
		const header_height = $('.site-header').height();
		const $topBar = $('.topbar');
		const $productPrice = $('.product__summary').find('.product-price');
		if ($topBar.length) {
			if ($productPrice.length) {
				if( $(window).scrollTop() + header_height  >= $('.product__summary .product-price').offset().top ){
					$topBar.addClass('active');
					$topBar.addClass('active-summary');
					$topBar.removeClass('active-features');
				}else{
					$topBar.removeClass('active');
					$topBar.removeClass('active-summary');
				}
			}
			const topbar_height = $topBar.height();
			if( $(window).scrollTop() + header_height + topbar_height  >= $('.product__features').offset().top ){
				$topBar.addClass('active');
				$topBar.addClass('active-features');
				$topBar.removeClass('active-summary');
			}
		}
		/* if( !$(window).scrollTop() ){
			$('.topbar').removeClass('active');
			$('.topbar').removeClass('active-summary');
		} */
	});	
})(window.jQuery);
