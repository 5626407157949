
<template>
  <div v-if="products.length > 0" class="cart-group">
    <div class="cart-row cart-row--head">
        <div class="cart-col">
            <div class="cart-col__block"><strong>POS</strong></div>
        </div>
    </div>
    <pos-row v-for="(item, index) in products" :key="index" :row-item="item" />
    <apollo-row/>
  </div>
</template>

<script>

import PosRow from './PosRow';
import ApolloRow from './ApolloRow';

import helpers from '../../helpers';

export default {
  name: "PosTable",
  components: {
    PosRow,
    ApolloRow
  },
  computed: {
    products() {
      const posProducts = [];
      const cartProducts = this.$store.state.cart.products;
      for (let i = 0; i < cartProducts.length; i++) {
        const product = helpers.getProductById(cartProducts[i].id);
        if (product.type === 'pos') {
          posProducts.push({
            item: cartProducts[i],
            cartIndex: i
          });
        }
      }
      return posProducts;
    }
  }
};
</script>
