
<template>
    <div class="topbar">
        <div class="container">
            <div class="topbar__wrapper">
                <div class="topbar__tabs">
                    <ul class="tabs">
                        <li><a href="#" data-scroll-to="product-summary" product-summary><strong v-html="strings.description"></strong></a></li>
                        <li><a href="#" data-scroll-to="product-features" product-features><strong v-html="strings.features"></strong></a></li>
                    </ul>
                </div>

                <div class="topbar__main">
                    <div class="product-price">
                        <div class="price-title"><strong v-html="strings.final_price"></strong></div>
                        <div class="price-main">
                            <div class="total-price">
                                <div id="product-totals" class="title title--md">
                                    {{ totalFormattedPrice() }}
                                </div>
                            </div>
                        </div>
                        <div class="price-note" v-html="strings.price_without_vat"></div>
                    </div>

                    <div class="product-add-cart__quantity">
                        <div class="title" v-html="strings.quantity"></div>
                        <div class="quantity">
                            <button @click.prevent="qtyDown" class="quantity__symbol minus">
                                <img :src="`${assetsUrls}/svg/minus.svg`" width="24" height="24" alt="quantity-minus"/>
                            </button>
                            <div class="quantity__number">{{ qty }}</div>
                            <button @click.prevent="qtyUp" class="quantity__symbol plus">
                                <img :src="`${assetsUrls}/svg/plus.svg`" width="24" height="24" alt="quantity-plus"/>
                            </button>
                        </div>
                    </div>

                    <button @click="addToCart" class="btn btn--orange btn--wide">
                        <span v-html="strings.add_to_cart_button"></span><span class="icon icon-arrow-right"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import helpers from '../../helpers';

export default {
  name: "TopBar",
  props: ['productData'],
  data() {
    return {
        strings: window.app_config.strings,
        assetsUrls: window.urls.assets,
        product: null,
        supportPlan: 0,
        color: '',
        qty: 1
    }
  },
  computed: {
    totalPrice() {
     let price = this.productData.price;
     if (this.productData.on_sale) {
        price = this.productData.sale_price;
     }
     if (this.productData.support_plans && this.productData.support_plans.length) {
        price = price + this.productData.support_plans[this.supportPlan].price;
     }
     return price * this.qty;
    }
  },
  beforeMount() {
    this.setDefaultColor();
    this.product = helpers.getProductById(this.$props.productData.id);
  },
  methods: {
    setDefaultColor() {
        if (this.$props.productData.type !== 'accessory') {
            return;
        }
        if (this.$props.productData.colors && this.$props.productData.colors.length) {
            this.selectedColor = this.$props.productData.colors[0];
        }
    },
    qtyDown() {
        if (this.qty === 1) return;
        this.qty--;
    },
    qtyUp() {
        if (this.qty >= 10) return;
        this.qty++;
    },
    addToCart() {
        const product = {
            id: this.productData.id,
            quantity: this.qty
        };
        if (this.product.type === 'pos') {
            product['supportPlanIndex'] = this.supportPlan;
        } else if (this.product.type === 'accessory') {
            product['color'] = this.color;
        }
        this.$store.commit('cart/add_product', { item: product });
    },
    totalFormattedPrice() {
        return helpers.getPrice(this.totalPrice);
    },
    planPrice(price) {
        return helpers.getPrice(price);
    }
  }
};
</script>
