
<template>
  <div v-if="product" class="minicart-item">
      <div class="minicart-item__image">
          <a :href="product.url" :title="product.title">
            <img :src="getImage()" :alt="product.title" class="product-image" />
          </a>
      </div>
      <div class="minicart-item__main">
          <div class="minicart-item__title">
              <p>{{ $props.productData.quantity }} x <span v-html="product.title"></span></p>
              <p v-if="$props.productData.supportPlanIndex !== undefined">
                {{ product.support_plans[$props.productData.supportPlanIndex].title }}
              </p>
          </div>
          <div class="minicart-item__price">
              <div class="price-note" v-html="strings.from"></div>
              <div class="total-price">{{ getTotalPrice() }}</div>
              <div v-if="product.on_sale" class="old-price">
                <span v-html="getPriceWithoutDiscount()"></span>
              </div>
          </div>
      </div>
      <div class="minicart-item__close">
          <a href="#" @click.prevent="removeItem">
            <img :src="`${assetsUrls}/svg/remove.svg`" width="35" height="35" alt=""/>
          </a>
      </div>
  </div>
</template>

<script>

import helpers from '../../helpers';

export default {
  name: "CartItem",
  props: ['productData', 'rowIndex'],
  data() {
    return {
      assetsUrls: window.urls.assets,
      strings: window.app_config.strings,
      product: null
    }
  },
  beforeMount() {
    this.product = helpers.getProductById(this.$props.productData.id);
  },
  methods: {
    getTotalPrice() {
      if (!this.product) {
        return;
      }
      let price = this.product.price;
      if (this.product.on_sale) {
        price = this.product.sale_price;
      }
      if (this.product.support_plans && this.product.support_plans.length) {
        if (this.productData.supportPlanIndex >= 0) {
          price = price + this.product.support_plans[this.productData.supportPlanIndex].price;
        }
      }
      return helpers.getPrice(price * this.productData.quantity);
    },
    getPriceWithoutDiscount() {
      if (!this.product) {
        return;
      }
      let price = this.product.price;
      if (this.product.support_plans && this.product.support_plans.length) {
        if (this.productData.supportPlanIndex >= 0) {
          price = price + this.product.support_plans[this.productData.supportPlanIndex].price;
        }
      }
      return helpers.getPrice(price * this.productData.quantity);
    },
    removeItem() {
      this.$store.commit('cart/remove_product', { index: this.rowIndex });
    },
    getImage() {
      if (this.product.type === 'accessory') {
        const color = this.product.colors.filter(item => item.name === this.$props.productData.color);
        if (color.length) {
          return color[0].image;
        }
      }
      return this.product.image;
    }
  }
};
</script>
