import Vue from 'vue';
import VueToast from 'vue-toast-notification';

Vue.use(VueToast);

Vue.config.productionTip = false;

import {store} from '../store';
import App from './App.vue';

const wrapper = '#mini-cart';

(($) => {
	if ($(wrapper).length) {
		new Vue({
			el: wrapper,
			store,
			render: h => h(App)
		});
	}
})(window.jQuery);