//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import helpers from '../../helpers';

export default {
  name: "Pos",
  props: ['productData'],
  data() {
    return {
        strings: window.app_config.strings,
        assetsUrls: window.urls.assets,
        supportPlan: 0,
        qty: 1
    }
  },
  computed: {
    totalPrice() {
     let price = this.productData.price;
     if (this.productData.on_sale) {
        price = this.productData.sale_price;
     }
     if (this.productData.support_plans && this.productData.support_plans.length) {
        price = price + this.productData.support_plans[this.supportPlan].price;
     }
     return price * this.qty;
    }
  },
  methods: {
    qtyDown() {
        if (this.qty === 1) return;
        this.qty--;
    },
    qtyUp() {
        if (this.qty >= 10) return;
        this.qty++;
    },
    addToCart() {
        const product = {
            id: this.productData.id,
            quantity: this.qty,
            supportPlanIndex: this.supportPlan
        };
        this.$store.commit('cart/add_product', { item: product });
        this.qty = 1;
        this.supportPlan = 0;
    },
    totalFormattedPrice() {
        return helpers.getPrice(this.totalPrice);
    },
    planPrice(price) {
        return helpers.getPrice(price);
    }
  }
};
