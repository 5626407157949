const $ = window.jQuery;
$(document).on('ready', () => {

  $('.faq__row').click((e) => {
    e.preventDefault();
    $(e.currentTarget).toggleClass('open');
    $(e.currentTarget).siblings().removeClass('open');
    $(e.currentTarget).find('.faq__row__content').slideToggle();
    $(e.currentTarget).siblings().find('.faq__row__content').slideUp();
  });

});