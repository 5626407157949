
<template>
<div>
  <div v-if="productData.support_plans.length" class="product-support">
    <h3 class="title title--sm" v-html="strings.choose_support_plan"></h3>
    <div class="product-support__wrapper">
        <div v-for="(item, index) in productData.support_plans" :key="index">
            <input v-model="supportPlan" :value="index" type="radio" :id="`plan_${index}`" class="visuallyhidden" />
            <label :for="`plan_${index}`" class="product-support__plan">
                <span class="product-support__plan__top">
                    <span v-if="item.title" class="title" v-html="item.title"></span>
                    <span v-if="item.subtitle" class="content" v-html="item.subtitle"></span>
                    <span v-if="item.price" class="price">{{ planPrice(item.price) }}</span>
                    <span class="check"></span>
                </span>
                <span v-if="item.details" class="product-support__plan__bottom">
                    <span class="content" v-html="item.details"></span>
                </span>
            </label>
        </div>
    </div>
  </div>
  <div class="product-add-cart">
    <div class="product-add-cart__quantity">
        <div class="title" v-html="strings.quantity"></div>
        <div class="quantity">
            <button @click.prevent="qtyDown" class="quantity__symbol minus">
                <img :src="`${assetsUrls}/svg/minus.svg`" width="24" height="24" alt="quantity-minus"/>
            </button>
            <div class="quantity__number">{{ qty }}</div>
            <button @click.prevent="qtyUp" class="quantity__symbol plus">
                <img :src="`${assetsUrls}/svg/plus.svg`" width="24" height="24" alt="quantity-plus"/>
            </button>
        </div>
    </div>
    <div class="product-price">
        <div class="price-title"><strong v-html="strings.final_price"></strong></div>
        <div class="price-main">
            <div class="total-price">
                <div id="product-totals" class="title title--md">{{ totalFormattedPrice() }}</div>
            </div>
        </div>
        <div class="price-note" v-html="strings.price_without_vat"></div>
    </div>
    <button @click="addToCart" class="btn btn--orange btn--wide">
        <span v-html="strings.add_to_cart_button"></span><span class="icon icon-arrow-right"></span>
    </button>
  </div>
</div>
</template>

<script>

import helpers from '../../helpers';

export default {
  name: "Pos",
  props: ['productData'],
  data() {
    return {
        strings: window.app_config.strings,
        assetsUrls: window.urls.assets,
        supportPlan: 0,
        qty: 1
    }
  },
  computed: {
    totalPrice() {
     let price = this.productData.price;
     if (this.productData.on_sale) {
        price = this.productData.sale_price;
     }
     if (this.productData.support_plans && this.productData.support_plans.length) {
        price = price + this.productData.support_plans[this.supportPlan].price;
     }
     return price * this.qty;
    }
  },
  methods: {
    qtyDown() {
        if (this.qty === 1) return;
        this.qty--;
    },
    qtyUp() {
        if (this.qty >= 10) return;
        this.qty++;
    },
    addToCart() {
        const product = {
            id: this.productData.id,
            quantity: this.qty,
            supportPlanIndex: this.supportPlan
        };
        this.$store.commit('cart/add_product', { item: product });
        this.qty = 1;
        this.supportPlan = 0;
    },
    totalFormattedPrice() {
        return helpers.getPrice(this.totalPrice);
    },
    planPrice(price) {
        return helpers.getPrice(price);
    }
  }
};
</script>
