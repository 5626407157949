//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import CartItem from './components/CartItem';

export default {
  name: "App",
  components: {
    CartItem
  },
  data() {
    return {
      assets: window.urls.assets,
      strings: window.app_config.strings,
      cartPage: window.app_config.pages.cart,
      showMiniCart: false
    }
  },
  computed: {
    cartCount() {
      const products = this.$store.state.cart.products;
      let count = 0;
      for (let i = 0; i < products.length; i++ ) {
        count += products[i].quantity;
      }
      return count;
    },
    cartProducts() {
      return this.$store.state.cart.products;
    }
  },
  beforeMount() {
    if (window.location.href !== window.app_config.pages.cart) {
      this.showMiniCart = true;
    }
  },
  methods: {
    getCounterLabel() {
      if (this.cartCount > 1) {
        return this.strings.products_lowercase;
      }
      return this.strings.product_lowercase;
    }
  }
};
