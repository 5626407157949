//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import helpers from '../../helpers';
import {productImages} from '../../../single-product/sliders';
const $ = window.jQuery;

export default {
  name: "Accessory",
  props: ['productData'],
  data() {
    return {
        strings: window.app_config.strings,
        assetsUrls: window.urls.assets,
        qty: 1,
        selectedColor: null
    }
  },
  computed: {
    totalPrice() {
     let price = this.productData.price;
     if (this.productData.on_sale) {
        price = this.productData.sale_price;
     }
     return price * this.qty;
    }
  },
  beforeMount() {
        this.setDefaultColor();
  },
  methods: {
    qtyDown() {
        if (this.qty === 1) return;
        this.qty--;
    },
    qtyUp() {
        if (this.qty >= 10) return;
        this.qty++;
    },
    addToCart() {
        const product = {
            id: this.productData.id,
            quantity: this.qty,
            color: this.selectedColor
        };
        this.$store.commit('cart/add_product', { item: product });
        this.qty = 1;
    },
    totalFormattedPrice() {
        return helpers.getPrice(this.totalPrice);
    },
    setDefaultColor() {
        if (this.$props.productData.colors && this.$props.productData.colors.length) {
            this.selectedColor = this.$props.productData.colors[0].name;
        }
    },
    colorChange() {
        const slide = $('.product-images').find(`[data-color=${this.selectedColor}]`);
        if (slide.length) {
            productImages.slideToLoop(slide.index());
        }
    }
  }
};
