
<template>
  <div class="cart-group cart-group--head">
    <div class="cart-row cart-row--head cart-row--noborder">
        <div class="cart-col cart-col--left">
            <div class="cart-col__block">
              <strong v-html="strings.products_camelcase"></strong>
            </div>
            <div v-if="productsWithSupportCount > 0" class="cart-col__block">
              <strong v-html="strings.support_plan"></strong>
              <img :src="`${assetsUrl}/svg/info.svg`" width="24" height="24" alt=""/>
            </div>
        </div>
        <div class="cart-col cart-col--right">
            <div class="cart-col__block cart-col__block--33">
              <strong v-html="strings.quantity"></strong>
            </div>
            <div class="cart-col__block cart-col__block--33">
              <strong v-html="strings.price"></strong>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import helpers from '../../helpers';

export default {
  name: "CartHead",
  computed: {
    productsWithSupportCount() {
      let count = 0;
      const cartProducts = this.$store.state.cart.products;
      for (let i = 0; i < cartProducts.length; i++) {
        const product = helpers.getProductById(cartProducts[i].id);
        if (product.type === 'pos') {
          count++
        }
      }
      return count;
    }
  },
  data() {
    return {
      assetsUrl: window.urls.assets,
      strings: window.app_config.strings,
    }
  }
};
</script>
