//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import helpers from '../../helpers';

export default {
  name: "TopBar",
  props: ['productData'],
  data() {
    return {
        strings: window.app_config.strings,
        assetsUrls: window.urls.assets,
        product: null,
        supportPlan: 0,
        color: '',
        qty: 1
    }
  },
  computed: {
    totalPrice() {
     let price = this.productData.price;
     if (this.productData.on_sale) {
        price = this.productData.sale_price;
     }
     if (this.productData.support_plans && this.productData.support_plans.length) {
        price = price + this.productData.support_plans[this.supportPlan].price;
     }
     return price * this.qty;
    }
  },
  beforeMount() {
    this.setDefaultColor();
    this.product = helpers.getProductById(this.$props.productData.id);
  },
  methods: {
    setDefaultColor() {
        if (this.$props.productData.type !== 'accessory') {
            return;
        }
        if (this.$props.productData.colors && this.$props.productData.colors.length) {
            this.selectedColor = this.$props.productData.colors[0];
        }
    },
    qtyDown() {
        if (this.qty === 1) return;
        this.qty--;
    },
    qtyUp() {
        if (this.qty >= 10) return;
        this.qty++;
    },
    addToCart() {
        const product = {
            id: this.productData.id,
            quantity: this.qty
        };
        if (this.product.type === 'pos') {
            product['supportPlanIndex'] = this.supportPlan;
        } else if (this.product.type === 'accessory') {
            product['color'] = this.color;
        }
        this.$store.commit('cart/add_product', { item: product });
    },
    totalFormattedPrice() {
        return helpers.getPrice(this.totalPrice);
    },
    planPrice(price) {
        return helpers.getPrice(price);
    }
  }
};
