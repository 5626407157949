
<template>
  <div>
    <cart-head/>
    <pos-table/>
    <accessories-table/>
    <totals/>
  </div>
</template>

<script>

import CartHead from './components/CartHead';
import PosTable from './components/PosTable';
import AccessoriesTable from './components/AccessoriesTable';
import Totals from './components/Totals';

export default {
  name: "App",
  components: {
    CartHead,
    PosTable,
    AccessoriesTable,
    Totals
  }
};
</script>
