const $ = window.jQuery;

$(document).on('ready', () => {
	const hashValue = window.location.hash.replace(/#/, '');
	const $toolbar = $('#wpadminbar');
	const $toolbarHeight = $toolbar.length & window.innerWidth > 600 ? $toolbar.innerHeight() : 0;
	const $globalNotification = $('.global-notification');
	const $globalNotificationHeight = $globalNotification.length & window.innerWidth > 998 ? $globalNotification.innerHeight() : 0;
	if (hashValue.length) {
		if ($(`#${hashValue}`).length) {
			setTimeout(() => {
				$('html, body').animate(
					{scrollTop: ($(`#${hashValue}`).offset().top - ($('.site-header').innerHeight() + $globalNotificationHeight + $toolbarHeight))},
					{duration: 0});
			}, 100);

		}
	}
});
