//
//
//
//
//
//
//
//
//


import TopBar from './components/TopBar';
import Pos from './components/Pos';
import Accessory from './components/Accessory';

import helpers from '../helpers';

export default {
  name: "App",
  components: {
    TopBar,
    Pos,
    Accessory
  },
  data() {
    return {
        product: null
    }
  },
  beforeMount() {
    this.product = helpers.getProductById(window.app_config.post_id);
  }
};
