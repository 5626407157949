//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import helpers from '../../helpers';

export default {
  name: "PosRow",
  props: ['rowItem'],
  data() {
    return {
      assetsUrl: window.urls.assets,
      strings: window.app_config.strings,
      product: null,
      selectedPlan: null
    }
  },
  computed: {
    cartItem() {
        return this.$store.state.cart.products[this.$props.rowItem.cartIndex];
    },
    lineTotal() {
        const cartItem = this.$store.state.cart.products[this.$props.rowItem.cartIndex];
        let total = this.getPosPrice(false);
        if (this.product.support_plans.length) {
            const supportPrice = this.product.support_plans[cartItem.supportPlanIndex].price;
            total = total + supportPrice;
        }
        return helpers.getPrice(total * this.cartItem.quantity);
    }
  },
  beforeMount() {
    this.product = helpers.getProductById(this.$props.rowItem.item.id);
    this.selectedPlan = this.cartItem.supportPlanIndex;
  },
  methods: {
    qtyDown() {
        this.$store.commit('cart/update_qty', { 
            index: this.$props.rowItem.cartIndex,
            type: 'minus'
        });
    },
    qtyUp() {
        this.$store.commit('cart/update_qty', { 
            index: this.$props.rowItem.cartIndex,
            type: 'plus'
        });
    },
    removeItem() {
      this.$store.commit('cart/remove_product', { index: this.rowIndex });
    },
    getPosPrice(formatted = true) {
        let price = this.product.price;
        if (this.product.on_sale) {
            price = this.product.sale_price;
        }
        if (formatted) {
            return helpers.getPrice(price);
        }
        return price;
    },
    getSupportPrice(amount) {
        return helpers.getPrice(amount);
    },
    supportPlanChange() {
        this.$store.commit('cart/update_support_plan', { 
            supportPlanIndex: this.selectedPlan,
            index: this.$props.rowItem.cartIndex
        });
    }
  }
};
