
<template>
    <div class="cart-row">
        <div class="cart-col cart-col--left">
            <div class="cart-col__block">
                <div class="product-cart-item">
                    <div class="product-cart-item__image">
                        <img :src="`${assetsUrl}/svg/cart-extra-item.svg`" width="20" height="44" alt=""/>
                    </div>
                    <div class="product-cart-item__image">
                        <img :src="`${assetsUrl}/svg/apollo-logo.svg`" width="68" height="53" alt=""/>
                    </div>
                    <div class="product-cart-item__main">
                        <p><strong>Cardlink apollo</strong></p>
                        <p>{{ strings.free_online_transactions_tracking }}</p> 
                    </div>
                </div>  
            </div>
        </div>
        <div class="cart-col cart-col--right">
            <div class="cart-col__block cart-col__block--33"> </div>
            <div class="cart-col__block cart-col__block--33"><strong>€0,00</strong></div>
            <div class="cart-col__block cart-col__block--33"></div>
        </div>
    </div>
</template>

<script>

export default {
  name: "ApolloRow",
  data() {
    return {
      assetsUrl: window.urls.assets,
      strings: window.app_config.strings,
    }
  }
};
</script>
