const $ = window.jQuery;
import Cookies from 'js-cookie';

function globalNotification() {
	const $globalNotification = $('.global-notification');
	const $toolbar = $('#wpadminbar');
	const $toolbarHeight = $toolbar.length & window.innerWidth > 600 ? $toolbar.innerHeight() : 0;
	const $headerHeight = $('.site-header').innerHeight();
	const cookieName = 'crd_notification';
	let $globalNotificationHeight = 0;
	
	const notificationStatus = Cookies.get(cookieName);
	if($globalNotification.length) {
		$globalNotification.css('top', `${$toolbarHeight}px`);
		if (window.innerWidth > 998) {
			$('body').removeClass('global-notifiation-vissible');
			if (typeof notificationStatus === 'undefined') {
				$globalNotificationHeight = $globalNotification.innerHeight();
				$('body').addClass('global-notifiation-vissible');	
			}
		} else {
			$('body').removeClass('global-notifiation-vissible');
			$globalNotificationHeight = 0;
		}
	}
	$('.site__wrapper').css('padding-top', `${$globalNotificationHeight + $headerHeight}px`);
	$('.site-header').css('top', `${$globalNotificationHeight + $toolbarHeight}px`);
	$('.site-header__main-menu').css('height', `${window.innerHeight - $globalNotificationHeight - $toolbarHeight}px`);
	$('.site-header__search').css('height', `${window.innerHeight - $globalNotificationHeight - $toolbarHeight}px`);
	document.documentElement.style.setProperty('--notif-height', `${$globalNotificationHeight + $toolbarHeight}px`);
}

$(document).on('ready', () => {
	const cookieName = 'crd_notification';
	const $globalNotification = $('.global-notification');
	const globalNotificationClose = $('.global-notification__close');
	const $toolbar = $('#wpadminbar');

	if ($globalNotification.length || $toolbar.length) {
		globalNotification();	
	}

	globalNotificationClose.on('click', () => {
		Cookies.set(cookieName, 'yes');
		globalNotification();
	});

	$(window).on('resize', () => {
		if ($globalNotification.length || $toolbar.length) {
			globalNotification();	
		}
	});
});
