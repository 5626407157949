import helpers from '../../helpers';

const strings = window.app_config.strings;

export default {
    namespaced: true,
    state: () => ({
        products: [],
        key: null
    }),
    mutations: {
        add_product(state, { item }) {
            // check if item is already in cart
            let maybeInCart = false;
            for (let i = 0; i < state.products.length; i++) {
                if (state.products[i].id === item.id) {
                    // item found
                    if ('supportPlanIndex' in state.products[i]) {
                        // Check if has same support plan
                        if (state.products[i].supportPlanIndex == item.supportPlanIndex) {
                            // is same. update quantity
                            state.products[i].quantity += item.quantity;
                            maybeInCart = true;
                            break;
                        }
                    } else if ('color' in state.products[i]) { // accessory product
                        // Check if has same color
                        if (state.products[i].color == item.color) {
                            // is same. update quantity
                            state.products[i].quantity += item.quantity;
                            maybeInCart = true;
                            break;
                        }
                    }
                }
            }
            if (!maybeInCart) {
                state.products.push(item);
                helpers.notice(strings.added_to_cart);
            } else {
                helpers.notice(strings.updated_cart);
            }
        },
        remove_product(state, { index }) {
            state.products.splice(index, 1);
        },
        update_qty(state, { index, type }) {
            const currentQty = state.products[index].quantity;
			if (type === "plus") {
				if (currentQty >= 10) {
					return;
				}
				state.products[index].quantity++;
			} else {
				if (currentQty <= 1) {
					return;
				}
				state.products[index].quantity--;
			}
        },
        update_support_plan(state, { supportPlanIndex, index }) {
            state.products[index].supportPlanIndex = supportPlanIndex;
        },
        empty(state) {
            state.products = [];
        }
    },
    actions: {
        
    }
}
