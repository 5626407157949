//
//
//
//
//
//
//
//
//
//
//
//


import AccessoryRow from './AccessoryRow';

import helpers from '../../helpers';

export default {
  name: "AccessoriesTable",
  components: {
    AccessoryRow
  },
  computed: {
    products() {
      const posProducts = [];
      const cartProducts = this.$store.state.cart.products;
      for (let i = 0; i < cartProducts.length; i++) {
        const product = helpers.getProductById(cartProducts[i].id);
        if (product.type === 'accessory') {
          posProducts.push({
            item: cartProducts[i],
            cartIndex: i
          });
        }
      }
      return posProducts;
    }
  },
  data() {
    return {
      assetsUrl: window.urls.assets,
      strings: window.app_config.strings,
    }
  }
};
