<template>
    <div>
        <div v-if="productData.colors.length" class="product-colors">
            <h3 class="title title--sm" v-html="strings.choose_color"></h3>
			<div class="colors">
                <div v-for="(item, index) in productData.colors" :key="index">
					<input v-model="selectedColor" @change="colorChange" :value="item.name" type="radio" :id="`color_${index}`" class="visuallyhidden" />
					<label :for="`color_${index}`" :style="{ 'background-color': item.name }" ></label>
			    </div>
		    </div>
		</div>
        <div class="product-add-cart">
            <div class="product-add-cart__quantity">
                <div class="title" v-html="strings.quantity"></div>
                <div class="quantity">
                    <button @click.prevent="qtyDown" class="quantity__symbol minus">
                        <img :src="`${assetsUrls}/svg/minus.svg`" width="24" height="24" alt="quantity-minus"/>
                    </button>
                    <div class="quantity__number">{{ qty }}</div>
                    <button @click.prevent="qtyUp" class="quantity__symbol plus">
                        <img :src="`${assetsUrls}/svg/plus.svg`" width="24" height="24" alt="quantity-plus"/>
                    </button>
                </div>
            </div>
            <div class="product-price">
                <div class="price-title"><strong v-html="strings.final_price"></strong></div>
                <div class="price-main">
                    <div class="total-price">
                        <div id="product-totals" class="title title--md">{{ totalFormattedPrice() }}</div>
                    </div>
                </div>
                <div class="price-note" v-html="strings.price_without_vat"></div>
            </div>
            <button @click="addToCart" class="btn btn--orange btn--wide">
                <span v-html="strings.add_to_cart_button"></span><span class="icon icon-arrow-right"></span>
            </button>
        </div>
    </div>
</template>

<script>

import helpers from '../../helpers';
import {productImages} from '../../../single-product/sliders';
const $ = window.jQuery;

export default {
  name: "Accessory",
  props: ['productData'],
  data() {
    return {
        strings: window.app_config.strings,
        assetsUrls: window.urls.assets,
        qty: 1,
        selectedColor: null
    }
  },
  computed: {
    totalPrice() {
     let price = this.productData.price;
     if (this.productData.on_sale) {
        price = this.productData.sale_price;
     }
     return price * this.qty;
    }
  },
  beforeMount() {
        this.setDefaultColor();
  },
  methods: {
    qtyDown() {
        if (this.qty === 1) return;
        this.qty--;
    },
    qtyUp() {
        if (this.qty >= 10) return;
        this.qty++;
    },
    addToCart() {
        const product = {
            id: this.productData.id,
            quantity: this.qty,
            color: this.selectedColor
        };
        this.$store.commit('cart/add_product', { item: product });
        this.qty = 1;
    },
    totalFormattedPrice() {
        return helpers.getPrice(this.totalPrice);
    },
    setDefaultColor() {
        if (this.$props.productData.colors && this.$props.productData.colors.length) {
            this.selectedColor = this.$props.productData.colors[0].name;
        }
    },
    colorChange() {
        const slide = $('.product-images').find(`[data-color=${this.selectedColor}]`);
        if (slide.length) {
            productImages.slideToLoop(slide.index());
        }
    }
  }
};
</script>
