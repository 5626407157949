//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "ApolloRow",
  data() {
    return {
      assetsUrl: window.urls.assets,
      strings: window.app_config.strings,
    }
  }
};
