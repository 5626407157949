
<template>
    <div v-if="products.length > 0" class="cart-group">
        <div class="cart-row cart-row--head">
            <div class="cart-col">
                <div class="cart-col__block"><strong v-html="strings.accessories"></strong></div>
            </div>
        </div>
        <accessory-row v-for="(item, index) in products" :key="index" :row-item="item" />
    </div>
</template>

<script>

import AccessoryRow from './AccessoryRow';

import helpers from '../../helpers';

export default {
  name: "AccessoriesTable",
  components: {
    AccessoryRow
  },
  computed: {
    products() {
      const posProducts = [];
      const cartProducts = this.$store.state.cart.products;
      for (let i = 0; i < cartProducts.length; i++) {
        const product = helpers.getProductById(cartProducts[i].id);
        if (product.type === 'accessory') {
          posProducts.push({
            item: cartProducts[i],
            cartIndex: i
          });
        }
      }
      return posProducts;
    }
  },
  data() {
    return {
      assetsUrl: window.urls.assets,
      strings: window.app_config.strings,
    }
  }
};
</script>
