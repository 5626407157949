
import Swiper from 'swiper';

const swiper = new Swiper(".product-images-navigation", {
	spaceBetween: 10,
	slidesPerView: 4,
	watchSlidesProgress: true,
});
export const productImages = new Swiper(".product-images-slider", {
	spaceBetween: 10,
	thumbs: {
		swiper: swiper,
	},
});

const related_slider = new Swiper(".products-slider", {
	spaceBetween: 10,
	slidesPerView: 1.2,
	breakpoints: {
		450: {
			slidesPerView: 1.9
		},
		600: {
			slidesPerView: 2.6
		},
		991: {
			slidesPerView: 3.5
		}
	}
});

const bottom_slider = new Swiper(".product-bottom-slider", {
	spaceBetween: 10,
	slidesPerView: 1,
	autoplay: {
		delay: 2500,
	},
});
