//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import helpers from '../../helpers';

export default {
    name: "AccessoryRow",
    props: ['rowItem'],
    data() {
        return {
            assetsUrl: window.urls.assets,
            strings: window.app_config.strings,
            product: null,
        }
    },
    computed: {
        cartItem() {
            return this.$store.state.cart.products[this.$props.rowItem.cartIndex];
        },
        lineTotal() {
            const total = this.getItemPrice(false);
            return helpers.getPrice(total * this.cartItem.quantity);
        }
    },
    beforeMount() {
        this.product = helpers.getProductById(this.$props.rowItem.item.id);
    },
    methods: {
        qtyDown() {
            this.$store.commit('cart/update_qty', { 
                index: this.$props.rowItem.cartIndex,
                type: 'minus'
            });
        },
        qtyUp() {
            this.$store.commit('cart/update_qty', { 
                index: this.$props.rowItem.cartIndex,
                type: 'plus'
            });
        },
        removeItem() {
            this.$store.commit('cart/remove_product', { index: this.rowIndex });
        },
        getItemPrice(formatted = true) {
            let price = this.product.price;
            if (this.product.on_sale) {
                price = this.product.sale_price;
            }
            if (formatted) {
                return helpers.getPrice(price);
            }
            return price;
        },
        getImage() {
            const color = this.product.colors.filter(item => item.name === this.$props.rowItem.item.color);
            if (color.length) {
                return color[0].image;
            }
            return this.product.image;
        }
    }
};
