const $ = window.jQuery;

$(document).on('ready', () => {
	const $scrollTo = $('[data-scroll-to]');
	$scrollTo.on('click', (e) => {
		const $this = $(e.currentTarget);
		e.preventDefault();
		const $duration = $this.parents('.rent-upgrade-pos').length ? 0 : 600;
		const $delay = $this.parents('.rent-upgrade-pos').length ? 10 : 0;
		const sectionId = $this.data('scroll-to');
		if ($(`#${sectionId}`).length) {
			setTimeout(() => {
				$('html, body').animate(
					{scrollTop: ($(`#${sectionId}`).offset().top - $('.site-header').innerHeight())},
					{duration: $duration});
			}, $delay)

		}
	});
});
