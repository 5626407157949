
<template>
    <div v-if="products.length" class="cart-group">
        <div class="cart-row">
            <div class="cart-col cart-col--50">
                <div class="cart-coupon">
                    <div class="cart-coupon__label" v-html="strings.have_a_coupon"></div>
                    <div class="cart-coupon__field">
                        <input type="text" v-model="couponCode" :placeholder="strings.coupon_number">
                        <a href="#" @click.prevent="applyCoupon" class="cart-coupon__field__icon">
                            <img :src="`${assetsUrl}/svg/arrow-right.svg`" width="29" height="17" alt="" class="submit"/>
                            <img :src="`${assetsUrl}/svg/check-green.svg`" width="13" height="10" alt="" class="check"/>
                        </a>
                        <img :src="`${assetsUrl}/svg/remove.svg`" width="24" height="24" alt="" class="remove"/>
                    </div>
                    <div class="cart-coupon__note"></div>
                </div>
            </div>
            <div class="cart-col cart-col--50">
                <div class="cart-totals">
                    <div class="cart-totals__row">
                        <div class="cart-totals__block" v-html="strings.products_value"></div>
                        <div class="cart-totals__block"><strong v-html="grandTotalExcludedVat"></strong></div>
                    </div>
                    <div class="cart-totals__row cart-totals__row--orange">
                        <div class="cart-totals__block" v-html="strings.coupon_discount"></div>
                        <div class="cart-totals__block"><strong>-€50,00</strong></div>
                    </div>
                    <div class="cart-totals__row">
                        <div class="cart-totals__block" v-html="strings.vat_total"></div>
                        <div class="cart-totals__block"><strong v-html="vatTotal"></strong></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cart-row cart-row--noborder">
            <div class="cart-col cart-col--50"></div>
            <div class="cart-col cart-col--50">
                <div class="cart-totals">
                    <div class="cart-totals__row cart-totals__row--big">
                        <div class="cart-totals__block" v-html="strings.grand_total"></div>
                        <div class="cart-totals__block"><strong v-html="grandTotal"></strong></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cart-row cart-row--noborder">
            <div class="cart-col cart-col--50">
                <a :href="pages.home" class="link" v-html="strings.continue_shopping"></a>
            </div>
            <div class="cart-col cart-col--50">
                <div class="cart-email">
                    <div class="cart-email__block">
                        <div class="cart-email__label">
                            <strong v-html="strings.fill_in_your_email_and_start"></strong>
                        </div>
                        <div class="cart-email__field">
                            <input type="email" v-model="userEmail" placeholder="Email *" />
                        </div>
                        <div class="cart-email__note" v-html="texts.email_note"></div>
                    </div>
                    <div class="cart-email__block">
                        <a href="#" @click.prevent="goToCheckout" title="" class="btn btn--orange btn--wide">
                            <span v-html="strings.continue"></span> <span class="icon icon-arrow-right"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import helpers from '../../helpers';

export default {
  name: "Totals",
  components: {
  },
  computed: {
    products() {
        return this.$store.state.cart.products;
    },
    vatTotal() {
        const cartItems = this.$store.state.cart.products;
        let total = 0;
        for (let i = 0; i < cartItems.length; i++ ) {
            total += helpers.getCartItemPrice(cartItems[i], false);
        }
        total = helpers.getVatFromPrice(total);
        return helpers.getPrice(total, false);
    },
    grandTotalExcludedVat() {
        const cartItems = this.$store.state.cart.products;
        let total = 0;
        for (let i = 0; i < cartItems.length; i++ ) {
            total += helpers.getCartItemPrice(cartItems[i], false);
        }
        return helpers.getPrice(total);
    },
    grandTotal() {
        const cartItems = this.$store.state.cart.products;
        let total = 0;
        for (let i = 0; i < cartItems.length; i++ ) {
            total += helpers.getCartItemPrice(cartItems[i], false);
        }
        return helpers.getPrice(total, false);
    }
  },
  data() {
    return {
      assetsUrl: window.urls.assets,
      strings: window.app_config.strings,
      pages: window.app_config.pages,
      texts: window.app_config.texts.cart,
      couponCode: null,
      userEmail: null
    }
  },
  methods: {
    applyCoupon() {

    },
    goToCheckout() {

    }
  }
};
</script>
