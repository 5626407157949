
<template>
    <div class="cart-row" v-if="product">
        <div class="cart-col cart-col--left">
            <div class="cart-col__block">
                <div class="product-cart-item">
                    <div class="product-cart-item__image">
                        <a :href="product.url" title="">
                            <img :src="product.image" alt="" class="product-image" />
                        </a>
                    </div>
                    <div class="product-cart-item__main">
                        <div class="product-cart-item">
                            <strong v-html="product.title"></strong>
                        </div>
                        <div class="product-cart-item">
                            {{ strings.pos_price }}:&nbsp;<strong v-html="getPosPrice()"></strong>
                        </div>
                    </div>
                </div>    
            </div>
            <div class="cart-col__block" v-if="product.support_plans">
                <select v-model="selectedPlan" @change="supportPlanChange" class="select">
                    <option v-for="(item, index) in product.support_plans" :key="index" :value="index">
                        {{ item.title }}: {{ getSupportPrice(item.price) }}
                    </option>
                </select>    
            </div>
        </div>
        <div class="cart-col cart-col--right">
            <div class="cart-col__block cart-col__block--33">
                <div class="quantity">
                    <button @click.prevent="qtyDown" class="quantity__symbol minus">
                        <img :src="`${assetsUrl}/svg/minus.svg`" width="24" height="24" alt=""/>
                    </button>
                    <div class="quantity__number">{{ cartItem.quantity }}</div>
                    <button @click.prevent="qtyUp" class="quantity__symbol plus">
                        <img :src="`${assetsUrl}/svg/plus.svg`" width="24" height="24" alt=""/>
                    </button>
                </div>
            </div>
            <div class="cart-col__block cart-col__block--33">
                <strong v-html="lineTotal"></strong>
            </div>
            <div class="cart-col__block cart-col__block--33">
                <a href="#" @click.prevent="removeItem" title="">
                    <img :src="`${assetsUrl}/svg/remove.svg`" width="24" height="24" alt=""/>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import helpers from '../../helpers';

export default {
  name: "PosRow",
  props: ['rowItem'],
  data() {
    return {
      assetsUrl: window.urls.assets,
      strings: window.app_config.strings,
      product: null,
      selectedPlan: null
    }
  },
  computed: {
    cartItem() {
        return this.$store.state.cart.products[this.$props.rowItem.cartIndex];
    },
    lineTotal() {
        const cartItem = this.$store.state.cart.products[this.$props.rowItem.cartIndex];
        let total = this.getPosPrice(false);
        if (this.product.support_plans.length) {
            const supportPrice = this.product.support_plans[cartItem.supportPlanIndex].price;
            total = total + supportPrice;
        }
        return helpers.getPrice(total * this.cartItem.quantity);
    }
  },
  beforeMount() {
    this.product = helpers.getProductById(this.$props.rowItem.item.id);
    this.selectedPlan = this.cartItem.supportPlanIndex;
  },
  methods: {
    qtyDown() {
        this.$store.commit('cart/update_qty', { 
            index: this.$props.rowItem.cartIndex,
            type: 'minus'
        });
    },
    qtyUp() {
        this.$store.commit('cart/update_qty', { 
            index: this.$props.rowItem.cartIndex,
            type: 'plus'
        });
    },
    removeItem() {
      this.$store.commit('cart/remove_product', { index: this.rowIndex });
    },
    getPosPrice(formatted = true) {
        let price = this.product.price;
        if (this.product.on_sale) {
            price = this.product.sale_price;
        }
        if (formatted) {
            return helpers.getPrice(price);
        }
        return price;
    },
    getSupportPrice(amount) {
        return helpers.getPrice(amount);
    },
    supportPlanChange() {
        this.$store.commit('cart/update_support_plan', { 
            supportPlanIndex: this.selectedPlan,
            index: this.$props.rowItem.cartIndex
        });
    }
  }
};
</script>
