//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import helpers from '../../helpers';

export default {
  name: "CartHead",
  computed: {
    productsWithSupportCount() {
      let count = 0;
      const cartProducts = this.$store.state.cart.products;
      for (let i = 0; i < cartProducts.length; i++) {
        const product = helpers.getProductById(cartProducts[i].id);
        if (product.type === 'pos') {
          count++
        }
      }
      return count;
    }
  },
  data() {
    return {
      assetsUrl: window.urls.assets,
      strings: window.app_config.strings,
    }
  }
};
